import Axios from 'axios'
import Cookies from 'universal-cookie'
import * as constants from '../constants'

const cookies = new Cookies()

var BASE_URL = constants.API_ENDPOINT

let expireOlderCookies = (name, paths) => {
  for (var i = 0, l = paths.length; i < l; i++) {
    cookies.remove(name, paths[i])
  }
}
let updateURLParameter = (url, param, paramVal) => {
  var newAdditionalURL = ''
  var tempArray = url.split('?')
  var baseURL = tempArray[0]
  var additionalURL = tempArray[1]
  var temp = ''
  if (additionalURL) {
    tempArray = additionalURL.split('&')
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i]
        temp = '&'
      }
    }
  }

  var tokn = temp + '' + param + '=' + paramVal
  return baseURL + '?' + newAdditionalURL + tokn
}
export let removeAuthCookies = () => {
  console.log('\n:::::::: Removing Auth Cookies :::::::: ')
  for (let i = 0; i < constants.AUTHENTICATION_COOKIES.length; i++) {
    cookies.remove(constants.AUTHENTICATION_COOKIES[i], {
      path: constants.PATH,
      domain: constants.DOMAIN,
    })
    console.log(
      '\t Removed - Cookie : ',
      constants.AUTHENTICATION_COOKIES[i],
      ', Domain : ',
      constants.DOMAIN,
      ', Path : ',
      constants.PATH
    )
  }
  console.log(':::::::: Removed Auth Cookies ::::::::\n')
}
Axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  async function (error) {
    const originalRequest = error.config
    console.log({ error }, { originalRequest })
    if (error.response.status === 498 && !originalRequest._retry) {
      originalRequest._retry = true // to avoid loops

      if (!error.response.config.url.includes('/g_at_w')) {
        console.log(
          ' ================= Calling /g_at_w for token renewal ============== '
        )
        try {
          // expireOlderCookies("at", ["/", "/dashboard"]);
          let at_updated_tkn = await renewAccessToken()

          console.log(
            ' --- Response from /g_at_w ::: ',
            { newAtToUse: at_updated_tkn },
            ' ---- '
          )

          if (!at_updated_tkn) {
            logoutUser() // will be called when the at token is not refreshed.
            return Promise.reject(error)
          }

          // three ways to use the new at token,
          // first, in req.body.tokn (for post calls)
          // second, in req.query.tokn (for get calls)
          // third, in req.headers.x-access-token

          if (
            error.response.config.method === 'POST' ||
            error.response.config.method === 'post'
          ) {
            let dataToAdd = JSON.parse(error.response.config.data)
            error.response.config.data = {
              ...dataToAdd,
              tokn: at_updated_tkn,
            }
          } else {
            let newURlwithQueryParams = updateURLParameter(
              error.response.config.url,
              'tokn',
              at_updated_tkn
            )
            console.log({ newURlwithQueryParams })
            error.response.config.url = newURlwithQueryParams
          }

          error.response.config.headers['x-access-token'] = at_updated_tkn

          console.log(
            '\n ---- Final API call ::: ',
            error.response.config,
            ' ---- \n'
          )

          // call the api which initially gave 498.
          return await Axios(error.response.config)
        } catch (error) {
          logoutUser() // will be called if there is an error in /g_at_w
          return Promise.resolve(error.response || error)
        }
      } else {
        logoutUser() // will be called if there is 498 issue but the route is NOT - /courselib and /g_at_w
        return Promise.resolve(error.response || error)
      }
    } else {
      // Do something with response error
      console.log('inside interceptor')
      return Promise.resolve(error.response || error)
    }
  }
)

export const renewAccessToken = (cb) => {
  console.log('g_at_w here::')
  return new Promise((resolve, reject) => {
    let opts = {
      method: 'post',
      url: BASE_URL + '/account/g_at_w',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        a_id: cookies.get('aid'),
        d_id: window.fingerprint.md5hash,
        at: cookies.get('at'),
        cl: 'W',
        rt: cookies.get('rt'),
        k_ey: cookies.get('secret'),
      },
    }
    console.log({ opts })
    Axios(opts)
      .then((res) => {
        if (res.data && res.data.success) {
          console.log({ data: res.data.data })
          var at = res.data.data.at
          var expiredate = new Date()
          expiredate.setDate(expiredate.getDate() + 180)
          cookies.set('at', at, {
            path: constants.PATH,
            domain: constants.DOMAIN,
          })
          return resolve(at)
        } else {
          console.error(res)
          resolve()
        }
      })
      .catch((err) => {
        console.error(err)
        resolve()
      })
  })
}
const logoutUser = () => {
  var data = {
    headers: {
      'Content-Type': 'application/json',
    },
    a_id: cookies.get('aid'),
    d_id: window.fingerprint.md5hash,
    at: cookies.get('at'),
    cl: 'W',
    rt: cookies.get('rt'),
    // k_ey	: utils.getSecret()
  }
  Axios.post(BASE_URL + '/auth/logout', data)
    .then((res) => {
      removeAuthCookies()
      var home_page_url
      if (
        location.pathname &&
        (location.pathname.includes('/applicationdoc/') ||
          location.pathname.includes('/certificate/'))
      ) {
        home_page_url =
          '/login' + '?redirect=' + encodeURIComponent(location.pathname)
      } else {
        home_page_url = '/login'
      }
      window.location.href = home_page_url
    })
    .catch((err) => {
      console.log({ err })
    })
}
export const fetchUserTransactions = async ({
  ccod = null,
  mobile = '',
  email = '',
  uid = null,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUser?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query = ''
    if (mobile) query += 'ccod=' + ccod + '&mobile=' + mobile
    if (email) query += 'email=' + email
    if (uid) query += 'uid=' + uid
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchUserTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export const fetchTokenTransactions = async ({
  search_val,
  search_type,
  crs_pg_id,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/fetchTokenTransactions'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        search_val,
        search_type,
        crs_pg_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchTokenTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export const fetchAllTransactions = async ({
  search_val,
  search_type,
  page,
  rowsPerPage,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/fetchAllTransactions'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        search_val,
        search_type,
        page,
        rowsPerPage,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAllTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export const fetchAllTransactionPacket = async ({
  allTransaction_id,
  gateway,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/fetchSpecificTransactionPacket'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        allTransaction_id,
        gateway,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchSpecificTransactionPacket data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getUserPermissions = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_own_permissions?'
    let query = ''
    let aid = cookies.get('aid')
    let isLoggedIn = cookies.get('isLoggedIn')
    let pid = cookies.get('pid')

    if (!isLoggedIn)
      return resolve({
        data: {
          permissionMap: {},
          permissions: { VIEW: 1, CREATE: 2, UPDATE: 3, DELETE: 4 },
          message: 'Login Again',
        },
        success: false,
        status: 400,
      })
    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    return Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data && res.data.success) {
          console.log('getUserPermissions data!!', res.data)
          return resolve({ ...res.data, success: true, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return reject({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log({ err })
        return reject(err)
      })
  })
}

export var getCourseAndAccount = ({
  cid,
  package_id,
  at,
  aid,
  installment = false,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/course/checkout_v2'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        cid,
        aid: aid || cookies.get('aid'),
        package_id,
        installment,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getCourseAndAccount data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchNextCustomerPaymentAmount = ({
  trxnId,
  aid,
  paymentType,
  installmentPlanId,
  emiPlanId,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getNextCustomerPaymentAmount'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        trxnId,
        aid,
        paymentType,
        installmentPlanId,
        emiPlanId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchNextCustomerPaymentAmount data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getManagerOtp = ({
  email,
  trxn_id,
  paymentType,
  expectedAmount,
  package_id,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/sendManagerOtp'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        email,
        trxn_id,
        paymentType,
        expectedAmount,
        package_id,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getManagerOtp data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var applyCustomerCashPayment = ({
  trxnId,
  aid,
  paymentType,
  amount,
  installmentPlanId,
  emiPlanId,
  // otp,
  email,
  selectedPaymentMode = 'Cash Payment',
  trxnNumber = '',
  subventionAmount,
  comment,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/applyCustomerPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        trxnId,
        aid,
        paymentType,
        amount,
        installmentPlanId,
        emiPlanId,
        // otp,
        email,
        selectedPaymentMode,
        trxnNumber,
        subventionAmount,
        comment,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('applyCustomerCashPayment data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var startFetchCourse = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/r_cr'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startFetchCourse data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const getUnallocatedPartnerTransactions = async ({
  limit = 5,
  skip = 0,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnallocatedPartnerTransactions?'
    let query = `limit=${limit}&skip=${skip}`
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUnallocatedPartnerTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var addPartnerInvoiceNumber = ({ invoiceNumber }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/addPartnerInvoice'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: { invoiceNumber },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('addPartnerInvoiceNumber data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var addStudentTokenNumber = ({ tokenId }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/addStudentTokenNumber'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: { tokenId },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('addStudentTokenNumber data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const getUnallocatedPayments = async ({
  crs_pg_id = '',
  loc_id = '',
  limit = 5,
  skip = 0,
  curr = 'INR',
  aid = null,
}) => {
  console.log({
    crs_pg_id,
    loc_id,
    limit,
    skip,
    curr,
    aid,
  })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnallocatedStudentTransactions?'
    let query = ''
    if (crs_pg_id) query += `&crs_pg_id=${crs_pg_id}`
    if (loc_id) query += `&loc_id=${loc_id}`
    if (limit) query += `&limit=${limit}`
    if (skip !== undefined) query += `&skip=${skip}`
    if (aid) query += `&aid=${aid}`
    query += `&curr=${curr}`
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUnallocatedTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var allocatePartnerAmountToIndividualPayments = ({
  payments,
  bankTransactionId,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/allocatePartnerBulkPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        payments,
        bankTransactionId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log(
            'allocatePartnerAmountToIndividualPayments data!!',
            res.data
          )
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const getUnallocatedBranchTransactions = async ({
  loc_id,
  limit = 5,
  skip = 0,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnallocatedBranchTransactions?'
    let query = `loc_id=${loc_id}`
    if (limit) query += `&limit=${limit}`
    if (skip !== undefined) query += `&skip=${skip}`
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUnallocatedBranchTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var allocateBranchAmountToIndividualPayments = ({
  payments,
  bankTransactionId,
  locId,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/allocateBranchBulkPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        payments,
        bankTransactionId,
        locId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log(
            'allocatePartnerAmountToIndividualPayments data!!',
            res.data
          )
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const getUnallocatedBranchPayments = async ({
  crs_pg_id = '',
  loc_id = '',
  limit = 5,
  skip = 0,
}) => {
  console.log({
    crs_pg_id,
    loc_id,
    limit,
    skip,
  })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnallocatedBranchStudentTransactions?'
    let query = ''
    if (crs_pg_id) query += `&crs_pg_id=${crs_pg_id}`
    if (loc_id) query += `&loc_id=${loc_id}`
    if (limit) query += `&limit=${limit}`
    if (skip !== undefined) query += `&skip=${skip}`
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUnallocatedTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export const checkAndCreateUser = async ({
  aid,
  ccod,
  mobile,
  email,
  name,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/checkAndCreateUserAccount?'
    let query = ''
    console.log({ query })
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        ccod,
        mobile,
        email,
        name,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('checkAndCreateUser data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export const installmentPlanOverride = async ({ trxn_id, installmentPlan }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/installmentPlanOverride?'
    let query = ''
    console.log({ query })
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        trxn_id,
        installmentPlan,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('installmentPlanOverride data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export function fetchCourseFeeStructures(id) {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/fetch_course_fee_structures?'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id: id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchCourseFeeStructures data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export const installmentPlanDatesOverride = async ({
  trxn_id,
  installmentPlan,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/installmentDatesOverride?'
    let query = ''
    console.log({ query })
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        trxn_id,
        installmentPlan,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('installmentPlanDatesOverride data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getCourses = (pkg) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/all_courses'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        package: pkg ? pkg : undefined,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getCourses data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getAllPossibleLocations = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_all_course_locations?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query+= 'crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_all_course_locations data!!', res.data)
          return resolve({ ...res.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var saveMposId = ({ loc_id, deviceId, deviceName }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/editMposId'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { loc_id, deviceId, deviceName },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getCourses data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var startMposPayment = ({
  trxnId,
  paymentType,
  aid,
  amount,
  installmentPlanId,
  emiPlanId,
  deviceId,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/startMposPayment'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        trxnId,
        paymentType,
        aid,
        amount,
        installmentPlanId,
        emiPlanId,
        deviceId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('startMposPayment data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var editSubventionAmount = ({
  customerPaymentId,
  updatedSubventionAmount,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/editSubventionAmount'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        customerPaymentId,
        updatedSubventionAmount,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('editSubventionAmount data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var toggleInstallmentCollectionModeApi = ({ trxn_id }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/toggleInstallmentCollectionMode'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: { trxn_id },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('toggleInstallmentCollectionModeApi data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var resendUserInvoice = ({ trxn_id, index }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/resendUserInvoice'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: { trxn_id, index },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('resendUserInvoice data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var resendTransactionNotification = ({ transactionId }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/resendTransactionNotification'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: { transactionId },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('resendTransactionNotification data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const fetchUserUnallocatedTransactions = async ({
  ccod,
  mobile,
  limit = 10,
  skip = 0,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnallocatedTokenTransactions?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query =
      'ccod=' + ccod + '&mobile=' + mobile + '&limit=' + limit + '&skip=' + skip
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchUserUnallocatedTransactions data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var allocateTokenAmountToIndividualPayments = ({
  payments,
  bankTransactionId,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/allocateTokenBulkPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        payments,
        bankTransactionId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log(
            'allocateTokenAmountToIndividualPayments data!!',
            res.data
          )
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var sendPaymentLinkToUser = ({ app_id, installment }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/sendPaymentLinkToUser'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        app_id,
        installment,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('sendPaymentLinkToUser data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchApplicationDocuments = ({ appId, captchaToken }) => {
  return new Promise((resolve, reject) => {
    let URL =
      BASE_URL +
      '/ops/fetchApplicationDocuments?appId=' +
      appId +
      '&captchaToken=' +
      captchaToken

    Axios({
      url: URL,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
    })
      .then((res) => {
        if (res.status && res.status == 200) {
          return resolve(res)
        } else {
          return reject(res)
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export var fetchStudentCertificate = ({ trxn_id, captchaToken }) => {
  return new Promise((resolve, reject) => {
    let URL =
      BASE_URL +
      '/ops/fetchStudentCertificate?trxn_id=' +
      trxn_id +
      '&captchaToken=' +
      captchaToken

    Axios({
      url: URL,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
    })
      .then((res) => {
        if (res.status && res.status == 200) {
          return resolve(res)
        } else {
          return reject(res)
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export var sendeNachPaymentLinkToUser = ({ aid, trxn_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/resendEnachSetupMail'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        aid,
        trxn_id,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('sendPaymentLinkToUser data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchAdminAdjustedPayments = ({ pageNo, pageLimit }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/fetch_admin_adjusted_payments'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        pageNo,
        pageLimit,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchAdminAdjustedPayments data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var retryPayment = ({
  aid,
  crs_pg_id,
  trxn_id,
  package_id,
  payment_type,
  installment_plan_id,
  installment_index,
  emi_plan_id,
  emi_index,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/retryPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at'),
      },
      data: {
        aid,
        crs_pg_id,
        trxn_id,
        package_id,
        payment_type,
        installment_plan_id: installment_plan_id
          ? installment_plan_id
          : undefined,
        installment_index:
          installment_index != undefined ? installment_index : undefined,
        emi_plan_id: emi_plan_id ? emi_plan_id : undefined,
        emi_index: emi_index != undefined ? emi_index : undefined,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('retryPayment data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getCaptchaSiteKey = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let endPoint
    if (process.env.REACT_APP_NODE_ENV == 'local') {
      endPoint = '/api/fetch_hcaptcha_key'
    } else {
      endPoint = '/api/api/fetch_hcaptcha_key'
    }
    let URL = BASE_URL + endPoint

    Axios({
      method: 'get',
      url: URL,
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetch_hcaptcha_key data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var requestEntryDeleteOtp = ({
  aid,
  trxn_id,
  singleDelete,
  comment,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/requestEntryDeleteOtp'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        aid,
        trxn_id,
        singleDelete,
        comment,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('requestEntryDeleteOtp data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var verifyEntryDeleteOtp = ({
  aid,
  trxn_id,
  singleDelete,
  otp_code,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/verifyEntryDeleteOtp'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        aid,
        trxn_id,
        singleDelete,
        otp_code,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('verifyEntryDeleteOtp data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var updatePseudoBatch = ({ trxn_id, proposed_batch_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/updatePseudoBatch'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        trxn_id,
        proposed_batch_id,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('updatePseudoBatch data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getPseudoBatches = ({ trxn_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getPseudoBatches'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        trxn_id,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getPseudoBatches data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getCategoryBasedCourses = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/get_ctrgy_courses'

    Axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/get_ctrgy_courses data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getUnverifiedKachings = ({
  skip,
  monthAndYear,
  crs_pg_id,
  mobile,
  ccod,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUnverifiedKachings'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        skip,
        monthAndYear,
        crs_pg_id,
        mobile,
        ccod,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/getUnverifiedKachings data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getPendingOrFailedUnverifiedKachings = ({ pageSize, pageNo }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getPendingOrFailedUnverifiedKachings'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        pageSize,
        pageNo,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/getPendingOrFailedUnverifiedKachings data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var getLeadFromMobile = ({ mobile, ccod }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/get_lead_by_mobile'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        mobile,
        ccod,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/get_lead_by_mobile data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var requestUnverifiedKachingOTP = ({
  crs_pg_id,
  package_id,
  prospect_id,
  revenue,
  sales_date,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/requestUnverifiedKachingCreationOTP'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        crs_pg_id,
        package_id,
        prospect_id,
        revenue,
        sales_date,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/requestUnverifiedKachingCreationOTP data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var resendUnverifiedKachingOTP = ({ otp_request_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/resendUnverifiedKachingCreationOTP'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        otp_request_id,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/resendUnverifiedKachingCreationOTP data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var verifyUnverifiedKachingOTP = ({ otp_request_id, otp_code }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/verifyUnverifiedKachingCreationOTP'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        otp_request_id,
        otp_code,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/verifyUnverifiedKachingCreationOTP data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var updateUnverifiedKachingOTP = ({
  kaching_id,
  revenue,
  sales_date,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/updateUnverifiedKaching'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        kaching_id,
        revenue,
        sales_date,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/updateUnverifiedKaching data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var deleteUnverifiedKachingOTP = ({ kaching_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/deleteUnverifiedKaching'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        kaching_id,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('/deleteUnverifiedKaching data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var createNewBatchDropRequest = ({
  trxn_id,
  comment,
  dropReason,
  aid,
}) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/createNewBatchDropRequest'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        trxn_id,
        comment,
        dropReason,
        aid,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('createNewBatchDropRequest data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchLocationsByCourse = ({ crs_pg_id }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelCourse/r_loc'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        // crs_pg_id,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchLocations data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getFilteredPossibleLocations = ({ crs_pg_ids = [], cids = [] }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_all_course_locations?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'crs_pg_id_arr=' + JSON.stringify(crs_pg_ids)
    query += '&crs_id_arr=' + JSON.stringify(cids)
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_all_course_locations data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var fetchBatchesOfCourse = ({ crs_pg_ids = [] }) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_ids })
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_course_batches?'
    let query = ''

    query += 'crs_pg_id_arr=' + JSON.stringify(crs_pg_ids)
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchBatchesOfCourse data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var fetchPotentialRiskStudents = ({
  crs_pg_id,
  cid,
  loc_id,
  timeRange,
}) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_id, cid, loc_id, timeRange })
    let statusCode
    let URL = BASE_URL + '/ops/fetchPotentialRiskStudents?'
    let query = ''

    if (crs_pg_id) query += '&crs_pg_id=' + crs_pg_id
    if (cid) query += '&cid=' + cid
    if (loc_id) query += '&loc_id=' + loc_id
    if (timeRange) query += '&timeRange=' + timeRange
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchPotentialRiskStudents data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var fetchOwnPendingRequests = ({
  crs_pg_id,
  cid,
  loc_id,
  timeRange,
}) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_id, cid, loc_id, timeRange })
    let statusCode
    let URL = BASE_URL + '/ops/getPendingBatchDropRequests?'
    let query = ''

    if (crs_pg_id) query += '&crs_pg_id=' + crs_pg_id
    if (cid) query += '&cid=' + cid
    if (loc_id) query += '&loc_id=' + loc_id
    if (timeRange) query += '&timeRange=' + timeRange
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchOwnPendingRequests data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var withdrawDropRequest = ({ requestId, comment }) => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/withdrawDropRequest'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        requestId,
        comment,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('withdrawDropRequest data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}

export var fetchActionableRequests = ({
  crs_pg_id,
  cid,
  loc_id,
  timeRange,
}) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_id, cid, loc_id, timeRange })
    let statusCode
    let URL = BASE_URL + '/ops/getAllActionableBatchDropRequests?'
    let query = ''

    if (crs_pg_id) query += '&crs_pg_id=' + crs_pg_id
    if (cid) query += '&cid=' + cid
    if (loc_id) query += '&loc_id=' + loc_id
    if (timeRange) query += '&timeRange=' + timeRange
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchActionableRequests data!!', res.data)
          return resolve({
            ...res.data.data,
            success: true,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
