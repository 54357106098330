import React, { useEffect, useState, lazy, Suspense } from 'react'
import Cookies from 'universal-cookie'
import { connect } from 'react-redux'
import { getUserPermissions, removeAuthCookies } from './actions/general'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
const App = lazy(() => import('./layout/App'))
const Home = lazy(() => import('./scenes/landingPage/landingPage'))
const PromoCode = lazy(() => import('./scenes/promocode/promocode'))
const SpecialPromocode = lazy(() =>
  import('./scenes/promocode/specialpromocode')
)
const GeneralPromocode = lazy(() =>
  import('./scenes/promocode/generalpromocode')
)
const AddSpecialPromocode = lazy(() =>
  import('./scenes/promocode/add_specialpromocode')
)
const AddGeneralPromocode = lazy(() =>
  import('./scenes/promocode/add_generalpromocode')
)
const EditGeneralPromoCode = lazy(() =>
  import('./scenes/promocode/edit_generalpromocode')
)
const Login = lazy(() => import('./scenes/login/index'))
const ApplicationsReview = lazy(() =>
  import('./scenes/applicationform/ApplicationsReview')
)
const CashPayment = lazy(() => import('./scenes/cashpayment/CashPayment'))
const PaymentStatus = lazy(() => import('./scenes/paymentStatus/ViewPayment'))
const PartnerCollectedAllocation = lazy(() =>
  import('./scenes/partnercollectedallocation/PartnerCollectedAllocation')
)
const BranchCollectedAllocation = lazy(() =>
  import('./scenes/branchcollectedallocation/BranchCollectedAllocation')
)
const EditRegistrationFee = lazy(() =>
  import('./scenes/editregistrationfee/editregistrationfee')
)
const Editapplicationfee = lazy(() =>
  import('./scenes/editapplicationfee/editapplicationfee')
)
const InstallmentEdit = lazy(() =>
  import('./scenes/installmentedit/InstallmentEdit')
)
const EditInstallmentDates = lazy(() =>
  import('./scenes/editinstallmentdates/EditInstallmentDates')
)
const CreateCustomerPreferences = lazy(() =>
  import('./scenes/CreateCustomerPreferences/CreateCustomerPreferences')
)
const ApplyTokenPayment = lazy(() =>
  import('./scenes/applyTokenPayment/ApplyTokenPayment')
)
const LegacyInstallmentCollection = lazy(() =>
  import('./scenes/legacyInstallment/legacyInstallment')
)
const LivePaymentTracking = lazy(() =>
  import('./scenes/livePaymentTracking/livePaymentTracking')
)
const ApplicationDocumentDownload = lazy(() =>
  import('./scenes/applicationdocumentdownload/applicationdocumentdownload')
)
const CertificateDownload = lazy(() =>
  import('./scenes/certificateDownload/certificateDownload')
)
const Loader = lazy(() => import('./scenes/applicationform/Loader'))
const BatchDrop = lazy(() => import('./scenes/batchDrop/BatchDrop'))

const cookies = new Cookies()
import LeadDistributionPanel from './scenes/leadDistribution/leadDistributionPanel'
import LeadDistributionPanelAdvisors from './scenes/leadDistribution/LeadDistributionPanelAdvisors'

const Dashboard = (props) => {
  console.log({ props })
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    console.log(
      'Location changed!, ',
      'Pathname: ',
      location.pathname,
      ', SearchQuery: ',
      location.search
    )
    let listener = async () => {
      let pathname = location.pathname
      try {
        let res = await getUserPermissions()
        console.log({ res })
        if (res.success) {
          props.dispatch(
            props.setPermissions(res.data.permissionMap, res.data.resources)
          )
        }
      } catch (err) {
        removeAuthCookies()
        if (
          location.pathname &&
          (location.pathname.includes('/applicationdoc/') ||
            location.pathname.includes('/certificate/'))
        ) {
          navigate(
            '/login' + '?redirect=' + encodeURIComponent(location.pathname)
          )
        } else {
          alert('FORBIDDEN')
          navigate('/login')
        }
      }
    }
    if (cookies.get('isLoggedIn')) {
      listener()
    }
  }, [location])

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<App />}>
          <Route path="/" element={<Home />} />
          <Route
            path="promocode"
            element={checkVisibility('7') ? <PromoCode /> : <Home />}
          />
          <Route
            path="promocode/generalpromocode"
            element={
              checkVisibility('7') && checkVisibility('30') ? (
                <GeneralPromocode />
              ) : (
                <Home />
              )
            }
          />
          <Route
            path="promocode/specialpromocode"
            element={checkVisibility('7') ? <SpecialPromocode /> : <Home />}
          />
          <Route
            path="promocode/specialpromocode/add"
            element={checkVisibility('7') ? <AddSpecialPromocode /> : <Home />}
          />
          <Route
            path="promocode/generalpromocode/add"
            element={
              checkVisibility('7') && checkVisibility('30') ? (
                <AddGeneralPromocode />
              ) : (
                <Home />
              )
            }
          />
          <Route
            path="promocode/generalpromocode/edit/:promoid"
            element={
              checkVisibility('7') && checkVisibility('30') ? (
                <EditGeneralPromoCode />
              ) : (
                <Home />
              )
            }
          />
          <Route
            path="applicationdoc/:applicationId"
            element={
              Object.keys(props.resources).length ? (
                <ApplicationDocumentDownload />
              ) : (
                <Loader loading />
              )
            }
          />
          <Route
            path="certificate/:txnId"
            element={
              Object.keys(props.resources).length ? (
                <CertificateDownload />
              ) : (
                <Loader loading />
              )
            }
          />
          <Route
            path="applicationsreview"
            element={checkVisibility('5') ? <ApplicationsReview /> : <Home />}
          />
          <Route
            path="paymentStatus"
            element={checkVisibility('33') ? <PaymentStatus /> : <Home />}
          />
          <Route
            path="cashpayment"
            element={checkVisibility('33') ? <CashPayment /> : <Home />}
          />
          <Route
            path="applyTokenPayment"
            element={checkVisibility('33') ? <ApplyTokenPayment /> : <Home />}
          />
          <Route
            path="partner_collected"
            element={
              checkVisibility('33') ? <PartnerCollectedAllocation /> : <Home />
            }
          />
          <Route
            path="imarticus_collected"
            element={
              checkVisibility('33') ? <BranchCollectedAllocation /> : <Home />
            }
          />
          <Route
            path="set_user_payment_preferences"
            element={
              checkVisibility('33') ? <CreateCustomerPreferences /> : <Home />
            }
          />
          <Route
            path="legacy_installment_collection"
            element={
              checkVisibility('37') ? <LegacyInstallmentCollection /> : <Home />
            }
          />
          <Route
            path="live_payment_tracking"
            element={<LivePaymentTracking />}
          />
          <Route
            path="edit-transaction/registration"
            element={checkVisibility('5') ? <EditRegistrationFee /> : <Home />}
          />
          <Route
            path="edit-transaction/application"
            element={checkVisibility('5') ? <Editapplicationfee /> : <Home />}
          />
          <Route
            path="edit-transaction/installment"
            element={checkVisibility('33') ? <InstallmentEdit /> : <Home />}
          />
          <Route
            path="edit-transaction/installmentdates"
            element={
              checkVisibility('33') ? <EditInstallmentDates /> : <Home />
            }
          />
          <Route
            path="leaddistributionpanel"
            element={
              checkVisibility('39') ? <LeadDistributionPanel /> : <Home />
            }
          />
          <Route
            path="LeadDistributionPanelAdvisors"
            element={
              checkVisibility('39') ? (
                <LeadDistributionPanelAdvisors />
              ) : (
                <Home />
              )
            }
          />
          <Route
            path="batchDropRequest"
            element={checkVisibility('54') ? <BatchDrop /> : <Home />}
          />
        </Route>
      </Routes>
    </Suspense>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
})
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissions: (permissions, resources) => (dispatch) => {
      return dispatch({
        type: 'SET_PERMISSIONS_AND_RESOURCES',
        payload: { permissions, resources },
      })
    },
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
